<script setup lang="ts">
import { useLayout } from '~/composable/layout.js';
import {ref} from "vue";

const { onMenuToggle } = useLayout();
const authStore = useAuthStore();

function signOut () {
  authStore.singOut();
  window.location.reload();
}
const items = ref([
  {label: 'Dashboard', icon: 'pi pi-fw pi-home', route: '/'},
  {label: 'Analytics', icon: 'pi pi-fw pi-chart-line', route: '/analytics'},
  {label: 'Publishers', icon: 'pi pi-fw pi-sitemap', route: '/publishers'},
  {label: 'Advertisers', icon: 'pi pi-fw pi-money-bill', route: '/advertisers'},
]);
</script>

<template>
  <div class="layout-topbar">
    <div class="layout-topbar-logo-container">
      <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
        <i class="pi pi-bars"></i>
      </button>
      <router-link to="/" class="layout-topbar-logo">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 100">
          <g fill="#22c55e">
            <path d="M60,50 A40,40 0 1,1 140,50 L100,90 Z"/>
            <circle cx="100" cy="50" r="15"/>
            <path d="M30,50 Q10,50 30,70 Q50,90 70,70" stroke="#22c55e" stroke-width="6" fill="none"/>
            <path d="M170,50 Q190,50 170,70 Q150,90 130,70" stroke="#22c55e" stroke-width="6" fill="none"/>
          </g>
        </svg>

        <span>PUBSERV</span>
      </router-link>
    </div>

    <div class="layout-topbar-actions">
      <div class="layout-topbar-menu flex">
        <Message severity="success" class="mr-5">{{ authStore.username }}</Message>
        <button type="button" class="layout-topbar-action" @click="signOut" title="Sign-Out">
          <i class="pi pi-sign-out"></i>
        </button>
      </div>
    </div>
  </div>
</template>
